import MedicationAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/medication-allergies/MedicationAllergiesTemplate';

import { MEDICAL_ALLERGY, OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/MedicationAllergies/MedicationAllergiesTemplate',
  component: MedicationAllergiesTemplate
};

const createStory = props => () => ({
  components: { MedicationAllergiesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><medication-allergies-template v-bind="$options.storyProps" /></div>'
});

export const withPreviousTetracyclinSelected = createStory({
  medicationTetracyclinVisible: false,
  medicationAllergies: [
    MEDICAL_ALLERGY.PENICILLIN,
    MEDICAL_ALLERGY.TEGRETOL,
    MEDICAL_ALLERGY.ASPRIN
  ],
  otherMedicationAllergies: ''
});

export const withoutPreviousTetracyclinSelected = createStory({
  medicationTetracyclinVisible: true,
  medicationAllergies: [
    MEDICAL_ALLERGY.TETRACYCLINE,
    MEDICAL_ALLERGY.PENICILLIN,
    MEDICAL_ALLERGY.TEGRETOL,
    MEDICAL_ALLERGY.ASPRIN
  ],
  otherMedicationAllergies: ''
});

export const otherMedicationAllergiesSelected = createStory({
  medicationTetracyclinVisible: true,
  medicationAllergies: [OTHER_VALUE_BINDINGS.value],
  otherMedicationAllergies: 'vitamin C'
});
